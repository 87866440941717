import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from '../services/common.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(private _commonService: CommonService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const userRoles = this._commonService.userRoles;

      // Check for the "invoicer" role
      if (userRoles.length === 1 && userRoles.includes('UserRole_Invoicer')) {
        // Redirect to the invoicing page
        this.router.navigate(['/invoicing/invoices']);
        return false; // Prevent navigation to the original route
      }
  
      // Redirect to the reservations page for all other cases
      this.router.navigate(['/reservations']);
      return false; // Prevent navigation to the original route
    }
  
  
}
